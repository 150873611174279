<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="130px" size="small">
				<el-form-item label="学期" prop="s_id">
				  <el-select v-model="form.s_id" placeholder="请选择学期" style="width: 100%;">
				    <el-option :value="item.id" :label="item.year+' '+item.tag" v-for="(item,index) in semester" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="社团名称" prop="title">
				  <el-input v-model="form.title" placeholder="请输入申请名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="申请项目">
					<div class="w-100 flex flex-column">
						<el-checkbox v-model="form.project_1">教师到岗出勤(0.5分)</el-checkbox>
						<el-checkbox v-model="form.project_2">指导社团或指导学生获校级及以上（含校级）教育行政主管部门组织的社团相关比赛中获奖(0.5分)</el-checkbox>
					</div>
				</el-form-item>
				<el-form-item label="获得奖项" prop="hdjx" v-if="form.project_2">
				  <el-select v-model="form.hdjx" placeholder="请选择获得奖项" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in items" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="上传材料" prop="files">
					<div class="flex flex-column flex-wrap">
						<div class="flex mr-1 mb-2">
							<el-upload
							  v-for="(item,index) in files" :key="index"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <div class="position-relative flex align-center flex-wrap w-100 overflow-hidden" style="height: 100px;" v-if="item.id">
								<img :src="item.path" style="width: 100px;height: 100px;" v-if="item.ext == 'jpg' || item.ext == 'jpeg' || item.ext == 'png'">
							  	<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;" v-else>{{item.name || ''}}</span>
							  	<i class="el-icon-delete text-white position-absolute flex-all" style="width: 25px;height: 25px;top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile(index)"></i>
							  </div>
							  <i v-else class="el-icon-plus uploader-icon"></i>
							</el-upload>
							<el-upload
							  v-if="files.length < 5"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <i class="el-icon-plus uploader-icon"></i>
							</el-upload>
						</div>
						<small class="d-block text-secondary" style="line-height: 20px;">上传照片及相关资料</small>
					</div>
				</el-form-item>
			</el-form>
			<div class="text-danger mt-2">
			  备注：教师到岗出勤总分为0.5分，缺勤一次扣0.2分，扣完为止。<br/>
			  所指导社团或指导学生获校级及以上（含校级）教育行政主管部门组织的社团相关比赛中获奖，指导教师加0.5分。
			</div>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'user_stzd',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				s_id:'',
				title:'',
				hdjx:'',
				project_1:false,
				project_2:false,
				files:null,
			},
			rules:{
				s_id: [
				  { required: true, message: '请选择学期', trigger: ['blur','change'] }
				],
				title: [
				  { required: true, message: '请填写社团名称', trigger: ['blur','change'] }
				],
				files: [
				  { required: true, message: '请上传文件', trigger: ['blur','change'] }
				],
			},
			items:[
				{id:0,name:'无'},
				{id:1,name:'省级'},
				{id:2,name:'市级'},
				{id:3,name:'校级'},
			],
			files:[],
		}
	},
	mounted() {
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 this.files = this.$route.query.info.files
		}
		this.getsemester()
	},
	methods:{
		reset() {
		  this.form = {
			 s_id:'',
			 title:'',
			 hdjx:'',
			 project_1:false,
			 project_2:false,
			 files:null,
		  }
		  this.resetForm('form')
		},
		uploadSuccess(e){
		   if(e.status){
			 this.files.push(e.data)
		   }
		},
		beforeUpload(){},
		delFile(index){
			this.files.splice(index,1)
		},
		handleSubmit() {
		  this.form.files = this.files
		  this.$refs.form.validate(valid => {
		    if (valid) {
			  if(this.form.project_2 && this.form.hdjx == ''){
				  this.$message.error('请选择获得奖项')
				  return false
			  }
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('申请成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>